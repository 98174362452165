<template>
  <div style="position: relative; height: 100%">
    <!-- 顶部导航条 -->
    <header v-if="isAirRoute">
      <div class="getBack" @click="$router.back(-1)">
        <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
        <span>返回</span>
      </div>
      <ul class="tabs">
        <li class="active" @click="changeTab(0)">航线</li>
        <li style="margin: 0 0.84rem" @click="changeTab(1)">照片</li>
        <li @click="changeTab(2)">视频</li>
      </ul>
      <div></div>
    </header>
    <main>
      <!--左方测栏-->
      <div class="leftBar" v-show="tab === 0" ref="leftBar">
        <!-- <div class="tabs"
           v-if="isAirRoute">
        <li class="active"
            @click="changeTab(0)">航线</li>
        <li @click="changeTab(1)">照片</li>
        <li @click="changeTab(2)">视频</li>
      </div> -->
        <!-- <router-view v-if="leftBarShou"
                   :ShowMarker="ShowMarker"
                   :shouAirRoute="shouAirRoute"
                   :shou3DAirRoute="shou3DAirRoute"
                   :close3DMap="close3DMap"></router-view> -->
        <router-view
          v-if="leftBarShou"
          :ShowMarker="ShowMarker"
          :shouAirRoute="shouAirRoute"
        ></router-view>
      </div>
      <div id="project" v-show="tab === 0">
        <!--高德地图容器-->
        <div class="centerMap" id="centerMap" v-show="!Shou3D"></div>
        <!--3D地图容器-->
        <!-- <div class="center3DMap"
           id="center3DMap"
           v-show="Shou3D"></div> -->
        <!--高德操作图标-->
        <div class="mapControl" ref="mapControl" v-show="!Shou3D">
          <div
            class="item"
            v-if="$online"
            @click="searchShowFn"
            ref="searchBar"
          >
            <img src="../../assets/img/Icon/search.png" alt />
          </div>
          <div class="item" ref="hierarchyBar">
            <img
              src="../../assets/img/Icon/hierarchy.png"
              @click="hierarchyFn"
            />
            <div class="hierarchyBox" v-if="hierarchyShou">
              <div>
                <Slider
                  v-model="maskOpacity"
                  show-tip="never"
                  :min="30"
                  @on-input="changeMask"
                ></Slider>
              </div>
              <div class="clearfix">
                <label>
                  <input type="radio" v-model="mapStyle1" value="平面" />
                  <span>平面</span>
                </label>
              </div>
              <div class="clearfix">
                <label>
                  <input type="radio" v-model="mapStyle1" value="卫星" />
                  <span>卫星</span>
                </label>
              </div>
              <hr />
              <div class="clearfix" v-if="$online">
                <label>
                  <input type="checkbox" value="路网" v-model="mapStyle2" />
                  <span>路网</span>
                </label>
              </div>
              <div class="clearfix">
                <label>
                  <input type="checkbox" value="地名" v-model="mapStyle2" />
                  <span>地名</span>
                </label>
              </div>
              <div class="clearfix" style="margin-bottom: 0">
                <label>
                  <input type="checkbox" value="虚拟护栏" v-model="mapStyle2" />
                  <span>虚拟护栏</span>
                </label>
              </div>
            </div>
          </div>
          <div class="item" ref="measureBar">
            <img
              src="../../assets/img/Icon/measure.png"
              @click="measureFn"
              alt
            />
            <div class="measureBox" v-if="measureShow">
              <div ref="stadiometry" @click="stadiometryFn">
                <span>测距</span>
              </div>
              <div ref="mSurface" @click="mSurfaceFn">
                <span>测面</span>
              </div>
            </div>
          </div>

          <div class="item" @click="zoomIn">
            <img src="../../assets/img/Icon/jia.png" alt />
          </div>
          <div class="item" @click="zoomOut">
            <img src="../../assets/img/Icon/jian.png" alt />
          </div>
        </div>
        <!--搜索框-->
        <div class="searchBox" v-show="searchShow">
          <input
            v-model="searchMsg"
            id="searchMap_2"
            autocomplete="off"
            placeholder="地点搜索"
          />
        </div>
        <!--3D地图切换图标-->
        <!-- <div :id="Shou3D ? 'Map3D' : ''"
           class="MapSwitch"
           ref="MapSwitch"
           v-if="MapSwitchIconShou"
           @click="Map3DSwitch">
        <img src="../../assets/img/Icon/Switch3dMap.png" />
      </div> -->
      </div>
      <!--展开条-->
      <!-- <div class="unfoldBth"
         ref="unfoldBth"
         @click="unfold">
      <i class="ivu-icon ivu-icon-ios-arrow-back"
         v-if="!unfoldStatus"></i>
      <i class="ivu-icon ivu-icon-ios-arrow-forward"
         v-if="unfoldStatus"></i>
    </div> -->

      <!-- 照片 -->
      <div v-show="tab === 1" style="width: 100%">
        <Spin size="large" v-if="loading" fix></Spin>
        <div v-for="(data, index) in imgdata" :key="index" class="img-list">
          <p class="media-date">
            {{ dateFormat(data.img_list_point[0].createTime) }}
          </p>
          <ul>
            <!-- <li
              v-for="(i, idx) in data.img_list_point"
              @click="bigImg(data, idx, i.imgId)"
              :key="idx"
            >
              <img :src="i.imgId ? i.thumPath : i.path" loading="lazy" />
              <p>
                {{ i.remark }}<i style="margin-left: 5px"></i>航点{{ idx + 1 }}
              </p>
              <span @click.stop="editImgName(i.imgId, i.remark)" v-if="i.imgId">
                <Icon type="ios-create-outline" color="#FFF" />
              </span>
            </li> -->
            <div>
              <li v-for="(item, index) in data.img_list_point" :key="index">
                <img
                  :src="item.thumPath"
                  @click="showImg(data.img_list_point, index)"
                />
                <!-- <img :src="item.path" alt="" /> -->
                <!-- <p class="media-date">{{ data.create_time }}</p> -->
                <!-- <Button
                type="error"
                size="small"
                shape="circle"
                class="delPicBtn"
                @click.stop="delMedia(data.id)"
                icon="md-close"
              >
              </Button> -->

                <p>
                  {{ item.remark }}<i style="margin-left: 5px"></i>航点{{
                    index + 1
                  }}
                </p>
                <span
                  @click.stop="editImgName(item.imgId, item.remark)"
                  v-if="item.imgId"
                >
                  <Icon type="ios-create-outline" color="#FFF" />
                </span>
                <span
                  @click.stop="scrawlImg(item)"
                  v-if="item.imgId"
                  class="scrawl"
                >
                  <Icon type="ios-brush-outline" color="#FFF" />
                </span>
              </li>
            </div>
          </ul>
        </div>
        <div v-if="imgdata.length === 0" class="noMedia">
          <img :src="noData" alt="" />
          暂无图片文件
        </div>
      </div>
      <!-- 视频 -->
      <div v-show="tab === 2" style="width: 100%">
        <Spin size="large" v-if="loading" fix></Spin>
        <!-- <p class="route-info"><span>{{dirName}}</span>{{filetitle}}</p> -->
        <ul class="video-list" v-if="videodata.length > 0">
          <li
            v-for="(i, idx) in videodata"
            @click="playVideo(i.path)"
            :key="idx"
          >
            <img :src="i.first_frame" />
          </li>
        </ul>
        <div v-if="videodata.length === 0" class="noMedia">
          <img :src="noData" alt="" />暂无视频文件
        </div>
      </div>
    </main>
    <!-- 图片视频大图弹窗 -->
    <BigImgModel
      v-if="imgModal"
      :imgList="bigImgList"
      :imgTime="bigImgTime"
      :idx="bigIndex"
      :configHeight="'100vh'"
      @closeBigImgModel="closeImgModal()"
    ></BigImgModel>
    <!-- 图片备注编辑弹窗 -->
    <Modal
      v-model="editImgNameModel"
      title="编辑图片名称"
      @on-ok="submitImgRemark"
      @on-cancel="cancelImgRemark"
    >
      <Input v-model="imgRemark" maxlength="15" show-word-limit />
    </Modal>
    <Modal
      class="drawModal"
      v-model="openDrawing"
      :transfer="false"
      fullscreen
      footer-hide
    >
      <div class="drawing-container">
        <div id="tui-image-editor"></div>
      </div>
    </Modal>
    <!-- 视频窗口 -->
    <article v-show="videoModal">
      <Icon
        type="md-close"
        color="white"
        class="close"
        size="30"
        @click="closeVideoModal()"
      />
      <video controls ref="video" :src="bigVideo" autoplay>
        <!-- <source :src="bigVideo" type="video/mp4" />
        您的浏览器不支持Video标签。 -->
      </video>
    </article>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import { GetNotFlyArea } from '@/utils/javaApi.js'
import filterMixin from '@/utils/filter.mixin.js'
import BigImgModel from '../statistics/commonComponents/bigImgModel_path.vue'
import Index from '../Panorama/index.vue'
import noData from '@/assets/img/noData.png'
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import ImageEditor from 'tui-image-editor'
const locale_zh = {
  ZoomIn: '放大',
  ZoomOut: '缩小',
  Hand: '手掌',
  History: '历史',
  Resize: '调整宽高',
  Crop: '裁剪',
  DeleteAll: '全部删除',
  Delete: '删除',
  Undo: '撤销',
  Redo: '反撤销',
  Reset: '重置',
  Flip: '镜像',
  Rotate: '旋转',
  Draw: '画',
  Shape: '形状标注',
  Icon: '图标标注',
  Text: '文字标注',
  Mask: '遮罩',
  Filter: '滤镜',
  Bold: '加粗',
  Italic: '斜体',
  Underline: '下划线',
  Left: '左对齐',
  Center: '居中',
  Right: '右对齐',
  Color: '颜色',
  'Text size': '字体大小',
  Custom: '自定义',
  Square: '正方形',
  Apply: '应用',
  Cancel: '取消',
  'Flip X': 'X 轴',
  'Flip Y': 'Y 轴',
  Range: '区间',
  Stroke: '描边',
  Fill: '填充',
  Circle: '圆',
  Triangle: '三角',
  Rectangle: '矩形',
  Free: '曲线',
  Straight: '直线',
  Arrow: '箭头',
  'Arrow-2': '箭头2',
  'Arrow-3': '箭头3',
  'Star-1': '星星1',
  'Star-2': '星星2',
  Polygon: '多边形',
  Location: '定位',
  Heart: '心形',
  Bubble: '气泡',
  'Custom icon': '自定义图标',
  'Load Mask Image': '加载蒙层图片',
  Grayscale: '灰度',
  Blur: '模糊',
  Sharpen: '锐化',
  Emboss: '浮雕',
  'Remove White': '除去白色',
  Distance: '距离',
  Brightness: '亮度',
  Noise: '噪音',
  'Color Filter': '彩色滤镜',
  Sepia: '棕色',
  Sepia2: '棕色2',
  Invert: '负片',
  Pixelate: '像素化',
  Threshold: '阈值',
  Tint: '色调',
  Multiply: '正片叠底',
  Blend: '混合色',
  Width: '宽度',
  Height: '高度',
  'Lock Aspect Ratio': '锁定宽高比例',
}
const customTheme = {
  'common.bi.image': '', // 左上角logo图片
  'common.bisize.width': '0px',
  'common.bisize.height': '0px',
  'common.backgroundImage': 'none',
  'common.backgroundColor': '#333',
  'common.border': '1px solid #333',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': '#333',
  'header.border': '0px',

  // load button
  'loadButton.backgroundColor': '#fff',
  'loadButton.border': '1px solid #ddd',
  'loadButton.color': '#222',
  'loadButton.fontFamily': 'NotoSans, sans-serif',
  'loadButton.fontSize': '12px',
  'loadButton.display': 'none', // 隐藏

  // download button
  'downloadButton.backgroundColor': '#fdba3b',
  'downloadButton.border': '1px solid #fdba3b',
  'downloadButton.color': '#fff',
  'downloadButton.fontFamily': 'NotoSans, sans-serif',
  'downloadButton.fontSize': '12px',
  'downloadButton.display': 'none', // 隐藏

  // icons default
  'menu.normalIcon.color': '#8a8a8a',
  'menu.activeIcon.color': '#555555',
  'menu.disabledIcon.color': '#ccc',
  'menu.hoverIcon.color': '#e9e9e9',
  'submenu.normalIcon.color': '#8a8a8a',
  'submenu.activeIcon.color': '#e9e9e9',

  'menu.iconSize.width': '24px',
  'menu.iconSize.height': '24px',
  'submenu.iconSize.width': '32px',
  'submenu.iconSize.height': '32px',

  // submenu primary color
  'submenu.backgroundColor': 'rgba(0, 0, 0, 0.3)',
  'submenu.partition.color': '#858585',

  // submenu labels
  'submenu.normalLabel.color': '#858585',
  'submenu.normalLabel.fontWeight': 'lighter',
  'submenu.activeLabel.color': '#fff',
  'submenu.activeLabel.fontWeight': 'lighter',

  // checkbox style
  'checkbox.border': '1px solid #ccc',
  'checkbox.backgroundColor': '#fff',

  // rango style
  'range.pointer.color': '#fff',
  'range.bar.color': '#666',
  'range.subbar.color': '#d1d1d1',

  'range.disabledPointer.color': '#414141',
  'range.disabledBar.color': '#282828',
  'range.disabledSubbar.color': '#414141',

  'range.value.color': '#fff',
  'range.value.fontWeight': 'lighter',
  'range.value.fontSize': '11px',
  'range.value.border': '1px solid #353535',
  'range.value.backgroundColor': '#151515',
  'range.title.color': '#fff',
  'range.title.fontWeight': 'lighter',

  // colorpicker style
  'colorpicker.button.border': '1px solid #1e1e1e',
  'colorpicker.title.color': '#fff',
}
export default {
  name: 'index',
  mixins: [filterMixin],
  components: { BigImgModel, Index },

  data: function () {
    return {
      openDrawing: false,
      instance: null,
      map: {},
      unfoldStatus: true,
      leftBarShou: true,
      searchMsg: '',
      searchShow: false,
      measureShow: false,
      stadiometryShou: true,
      mSurfaceShou: false,
      mouseTool: {},
      mapStyle1: '卫星',
      mapStyle2: [],
      hierarchyShou: false,
      satelliteLayer: {},
      roadNetLayer: {},
      infoWindow: {},
      MapSwitchIconShou: false,
      Shou3D: false,
      NFzone: [], // 虚拟护栏合集
      isAirRoute: false, // 顶部栏显示
      tab: 0, // 顶部栏切换
      teamID: '',
      page: 1,
      total: 10,
      imgdata: [],
      videodata: [],
      imgModal: false,

      bigIndex: 0,
      bigImgList: [],
      bigImgTime: '',

      videoModal: false,
      bigVideo: '',
      delID: '',
      delModel: false,
      viewer: '',
      loading: true,
      editImgNameModel: false,
      editImgNameId: '',
      imgRemark: '',
      //   filetitle: '',
      //   dirName: '',
      maskOpacity: 65,
      mask: {},
      noData,
    }
  },

  watch: {
    $route(to, from) {
      if (to.path == '/index/project/TaskBar') {
        console.log('当前路由是航线页面')
        // this.MapSwitchIconShou=true
      } else {
        this.tab = 0
        this.isAirRoute = false
        this.MapSwitchIconShou = false
      }
    },
    mapStyle1(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer)
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer)
      }
    },
    mapStyle2(val) {
      //路网,虚拟护栏显示
      // 路网
      if (val.indexOf('路网') !== -1) {
        this.map.add(this.roadNetLayer)
      } else {
        this.map.remove(this.roadNetLayer)
      }
      // 虚拟护栏
      if (val.indexOf('虚拟护栏') !== -1) {
        console.log(this.NFzone.length)
        if (this.NFzone.length > 0) {
          this.map.add(this.NFzone)
          //   this.NFzone.forEach((element) => {
          //     element.show();
          //   });
        } else {
          this.initNFzone()
        }
      } else {
        this.map.remove(this.NFzone)
        // this.NFzone.forEach((element) => {
        //   element.hide();
        // });
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        let features = ['bg', 'road', 'building', 'point']
        this.map.setFeatures(features)
      } else {
        let features = ['bg', 'road', 'building']
        this.map.setFeatures(features)
      }
    },
  },

  methods: {
    // 侧边栏伸缩动画
    // unfold() {
    //   console.log(this.$refs.leftBar.offsetWidth);
    //   var width = this.$refs.leftBar.offsetWidth / 100 + 'rem';
    //   console.log('width', width);
    //   if (this.unfoldStatus) {
    //     this.$refs.leftBar.style.right = `-${width}`;
    //     this.$refs.leftBar.style['transition'] = `right 1s`;
    //     this.$refs.unfoldBth.style.right = 0;
    //     this.$refs.unfoldBth.style['transition'] = `right 1s`;
    //     this.$refs.mapControl.style.right = 0;
    //     this.$refs.mapControl.style['transition'] = `right 1s`;
    //     this.unfoldStatus = false;
    //   } else {
    //     this.$refs.leftBar.style.right = 0;
    //     this.$refs.leftBar.style['transition'] = `right 1s`;
    //     this.$refs.unfoldBth.style.right = width;
    //     this.$refs.unfoldBth.style['transition'] = `right 1s`;
    //     this.$refs.mapControl.style.right = width;
    //     this.$refs.mapControl.style['transition'] = `right 1s`;
    //     this.unfoldStatus = true;
    //   }
    // },
    // 控件方法
    // 搜索
    searchShowFn() {
      this.searchShow = !this.searchShow
      if (this.searchShow) {
        this.$refs.searchBar.style.opacity = 1
      } else {
        this.$refs.searchBar.style.opacity = 0.6
      }
      let that = this
      let autoComplete = new AMap.Autocomplete({ input: 'searchMap_2' })
      console.log(autoComplete)
      AMap.event.addListener(autoComplete, 'select', function (e) {
        that.map.setCenter([e.poi.location.lng, e.poi.location.lat])
      })
    },
    // search() {
    //   var _this = this;
    //   var geocoder = new AMap.Geocoder();
    //   var address = this.searchMsg;
    //   console.log(address);
    //   geocoder.getLocation(address, function (status, result) {
    //     if (status === 'complete' && result.geocodes.length) {
    //       var lnglat = result.geocodes[0].location;
    //       // console.log("lnglat",lnglat);
    //       _this.map.setCenter([lnglat.lng, lnglat.lat]); //设置地图中心点
    //     } else {
    //       _this.$Message.error('根据地址查询位置失败');
    //     }
    //   });
    // },
    // 测量绘制方法
    draw(type) {
      var _this = this
      switch (type) {
        case 'rule': {
          _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF33FF',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          })
          break
        }
        case 'measureArea': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          })
          break
        }
      }
    },
    // 测量
    measureFn() {
      this.measureShow = !this.measureShow
      this.mouseTool.close(true) //关闭，并清除覆盖物

      if (this.measureShow) {
        this.$refs.measureBar.style.opacity = 1
        this.draw('rule') //默认加载测距
      } else {
        this.$refs.measureBar.style.opacity = 0.6
        this.mouseTool.close(true) //关闭，并清除覆盖物
        this.stadiometryShou = true
        this.mSurfaceShou = false
      }
      // 未完待续====================
    },
    // 测距
    stadiometryFn() {
      this.stadiometryShou = !this.stadiometryShou
      this.mSurfaceShou = false
      this.$refs.mSurface.style.opacity = 0.6
      this.mouseTool.close(true) //关闭，并清除覆盖物
      if (this.stadiometryShou) {
        this.$refs.stadiometry.style.opacity = 1
        this.draw('rule') //开启测距
      } else {
        this.$refs.stadiometry.style.opacity = 0.6
      }
    },
    // 测面积
    mSurfaceFn() {
      this.mSurfaceShou = !this.mSurfaceShou
      this.stadiometryShou = false
      this.$refs.stadiometry.style.opacity = 0.6
      this.mouseTool.close(true) //关闭，并清除覆盖物
      if (this.mSurfaceShou) {
        this.$refs.mSurface.style.opacity = 1
        this.draw('measureArea') //开启测面
      } else {
        this.$refs.mSurface.style.opacity = 0.6
      }
    },
    // 开关地图类型勾选列表
    hierarchyFn() {
      this.hierarchyShou = !this.hierarchyShou
      console.log(this.hierarchyShou)
      if (this.hierarchyShou) {
        this.$refs.hierarchyBar.style.opacity = 1
      } else {
        this.$refs.hierarchyBar.style.opacity = 0.6
      }
    },
    // 放大
    zoomIn() {
      this.map.zoomIn()
    },
    // 缩小
    zoomOut() {
      this.map.zoomOut()
    },
    // 显示地图上的任务信息
    ShowMarker(listData) {
      console.log('显示地图上的任务信息')
      this.infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, -30) })
      this.map.clearMap()

      //创建图标,绑定事件
      listData.forEach((item) => {
        var iconImg
        let firstlocal
        if (item.tasktype == 'airroute') {
          iconImg = require('../../assets/img/Marker/line_green.png')
          firstlocal = item.waypoint[0]
        } else if (item.tasktype == 'figure') {
          iconImg = require('../../assets/img/Marker/pic_red.png')
          firstlocal = item.local_data[0]
        }
        if (firstlocal) {
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: iconImg,
              size: new AMap.Size(25, 40),
            }),
            offset: new AMap.Pixel(-15, -40),
            position: [
              Number(eval(firstlocal).lng),
              Number(eval(firstlocal).lat),
            ],
          })
          marker.setLabel({
            offset: new AMap.Pixel(-50, -28),
            content: `<div class="mark_label"><P>${item.filetitle}</P></div>`,
          })
          marker.content = `<div class="marker-content" id=#${item.pm_id}>
                    <ul>
                        <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.filetitle}</li>
                        <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                        <li><i class="ivu-icon ivu-icon-md-person"></i>${item.name}</li>
                        <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入任务</li>
                    </ul>
                </div>
                  `
          marker.data = item
          marker.id = item.pm_id
          this.map.add(marker)
          marker.on('mouseover', this.shouInfoWindow)
        }
      })
      if (listData.length == 0) {
        this.map.setZoomAndCenter(11, this.$Center)
      } else {
        this.map.setFitView()
      }
      if (this.NFzone.length > 0 && this.mapStyle2.indexOf('虚拟护栏') !== -1) {
        setTimeout(() => {
          this.map.add(this.NFzone)
        }, 500)
      }
      console.log('a1')
      this.mask = new AMap.Polygon({
        path: [
          new AMap.LngLat(-360, 90, true),
          new AMap.LngLat(-360, -90, true),
          new AMap.LngLat(360, -90, true),
          new AMap.LngLat(360, 90, true),
        ],
        strokeColor: '#000',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 1 - this.maskOpacity / 100,
        bubble: true,
      })
      this.map.add(this.mask)
    },
    // 移入显示详细信息
    shouInfoWindow(e) {
      var _this = this
      this.infoWindow.setContent(e.target.content)
      this.infoWindow.open(this.map, e.target.getPosition())
      // 点击信息进入工程,任务
      setTimeout(function () {
        var a = document.getElementById(`#${e.target.id}`)
        a.onclick = function () {
          _this.IntoPproject(e.target.data)
        }
      }, 80)
    },
    //进入任务
    IntoPproject(e) {
      //   console.log('//进入工程,任务', e, this.$route.name);
      //   console.log('进入任务', e);
      if (e.tasktype == 'airroute') {
        //点击航线
        this.shouAirRoute(e, '航线')
      } else if (e.tasktype == 'figure') {
        //点击建图
        this.shouAirRoute(e, '建图')
      }
      this.IntoheTask(e)

      // if (this.$route.name == "一级工程列表详情") {
      //     console.log("进入一级工程,展示二级工程列表");
      //     this.$_bus.$emit("Into_1_project", e);
      // } else if (this.$route.name == "二级工程列表详情") {
      //     console.log("进入二级工程,展示任务列表");
      //     this.$_bus.$emit("Into_2_project", e);
      // } else if (this.$route.name == "任务列表详情") {
      //     console.log("进入任务", pm_id, e);
      //     if (e.tasktype == "airroute") {
      //         //点击航线
      //         this.shouAirRoute(e, "航线");
      //     } else if (e.tasktype == "figure") {
      //         //点击建图
      //         this.shouAirRoute(e, "建图");
      //     }
      //     this.IntoheTask(e);
      // }
    },
    // 点击列表进入任务
    IntoheTask(item) {
      var _this = this
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      this.$router.push({
        path: '/index/project/TaskBar',
      })
    },
    // 显示航线或建图
    shouAirRoute(Data, Type) {
      console.log('显示航线', Data, Type)
      let _this = this
      //   this.initNFzone();

      this.map.clearMap()

      if (this.NFzone.length > 0 && this.mapStyle2.indexOf('虚拟护栏') !== -1) {
        this.map.add(this.NFzone)
      }
      if (Type == '航线') {
        this.isAirRoute = true
        this.MapSwitchIconShou = true
        let waypoint = Data.waypoint
        var path = []
        //通过接口判断该航线有无航线照片
        this.$post(Api.airRouteMedia(), {
          air_route_id: Data.id,
        }).then((res) => {
          let imgList = res.data.img_list
          waypoint.forEach((item) => {
            path.push([item.lng, item.lat, item.altitude])
          })
          if (imgList.length > 0) {
            //画点,有航线照片
            path.forEach((item, ind) => {
              let simpleMarker = new AMap.Marker({
                position: [item[0], item[1]], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                icon: new AMap.Icon({
                  size: new AMap.Size(60, 60), // 图标尺寸
                  image: require('../../assets/img/Marker/MediaImg.png'), // Icon的图像
                  imageSize: new AMap.Size(60, 60), // 根据所设置的大小拉伸或压缩图片
                }),
                label: {
                  offset: new AMap.Pixel(0, 5), //设置文本标注偏移量--设置后位置错误
                  content: `
                    <div style="position: relative;">
                      <div style="line-height: 20px;color:#333">高度${
                        item[2]
                      }m</div>
                      <div style=" position: absolute;  width: 17px;height: 15px;background: #fff;
                        bottom: -37.0px;left:26.01px;color:#333;cursor:pointer">${
                          ind + 1
                        }</div>
                    </div>`, //设置文本标注内容
                  direction: 'top', //设置文本标注方位
                },
                offset: new AMap.Pixel(-30, -30),
                map: _this.map,
              })
              simpleMarker.ind = ind
              simpleMarker.DATA = Data
              simpleMarker.on('click', _this.shouPicture)
            })
          } else {
            //画点,无航线照片
            path.forEach((item, ind) => {
              let simpleMarker = new AMap.Marker({
                position: [item[0], item[1]], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                icon: new AMap.Icon({
                  size: new AMap.Size(25, 25), // 图标尺寸
                  image: require('../../assets/img/Marker/waypoint.png'), // Icon的图像
                  imageSize: new AMap.Size(25, 25), // 根据所设置的大小拉伸或压缩图片
                }),
                label: {
                  offset: new AMap.Pixel(-0, -5), //设置文本标注偏移量--设置后位置错误
                  content: `
                  <div style="position: relative;">
                    <div style="line-height: 20px;color:#333;display:flex;
                      justify-content:center;align-items:center;">高度${
                        item[2]
                      }m</div>
                    <div style=" position: absolute;  width: 18px;height: 18px;
                      bottom: -31.5px;left:25px;color:#333;">${ind + 1}</div>
                  </div>`, //设置文本标注内容
                  direction: 'top', //设置文本标注方位
                },
                offset: new AMap.Pixel(-15, -15),
                map: _this.map,
              })
              simpleMarker.ind = ind
              simpleMarker.DATA = Data
              simpleMarker.on('click', _this.shouPicture)
            })
          }

          //画线
          var polyline = new AMap.Polyline({
            path: path,
            // isOutline: true,
            // outlineColor: '#fff',
            borderWeight: 0,
            // strokeColor: '#4C98FF',
            strokeColor: '#0AEF8B',
            // strokeOpacity: 1,
            // strokeWeight: 6,
            strokeStyle: 'solid',
            strokeDasharray: [10, 5],
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 50,
          })

          polyline.setMap(_this.map)
          // 缩放地图到合适的视野级别
          _this.map.setFitView([polyline])
        })
      } else if (Type == '建图') {
        let local_data = Data.local_data
        var path = []
        local_data.forEach((item) => {
          path.push([item.lng, item.lat])
        })
        //画点
        path.forEach((item) => {
          let marker = new AMap.Marker({
            position: item,
          })
          marker.DATA = Data
          this.map.add(marker)
          //   marker.on('click', this.shouOrthophotoMap);
        })

        // 画多边形
        var polygon = new AMap.Polygon({
          path: path,
          strokeColor: '#FF33FF',
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: '#1791fc',
          zIndex: 50,
        })
        polygon.DATA = Data
        this.map.add(polygon)
        // 缩放地图到合适的视野级别
        this.map.setFitView([polygon])
        // polygon.on('click', this.shouOrthophotoMap);
      }
      console.log('b2')

      _this.mask = new AMap.Polygon({
        path: [
          new AMap.LngLat(-360, 90, true),
          new AMap.LngLat(-360, -90, true),
          new AMap.LngLat(360, -90, true),
          new AMap.LngLat(360, 90, true),
        ],
        strokeColor: '#000',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 1 - _this.maskOpacity / 100,
        bubble: true,
      })
      this.map.add(_this.mask)
    },
    // 点击了航点 查看照片
    shouPicture(e) {
      this.$_bus.$emit('Into_Picture', e.target)
    },
    // 创建Cesium地图
    // Init_map_cesium() {
    //   let that = this;
    //   // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
    //   Cesium.Ion.defaultAccessToken = Api.CesiumToken();
    //   //镜头默认范围(必须在viewer实例创建前设置)
    //   // 定位在中国上空
    //   Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
    //     73.0,
    //     3.0,
    //     135.0,
    //     53.0
    //   );

    //   that.viewer = new Cesium.Viewer('center3DMap', {
    //     animation: false, //是否创建动画小器件，左下角仪表
    //     baseLayerPicker: false, //是否显示图层选择器
    //     fullscreenButton: false, //是否显示全屏按钮
    //     geocoder: false, //是否显示geocoder小器件，右上角查询按钮
    //     homeButton: true, //是否显示Home按钮
    //     infoBox: false, //是否显示信息框
    //     sceneModePicker: false, //是否显示3D/2D选择器
    //     selectionIndicator: false, //是否显示选取指示器组件
    //     timeline: false, //是否显示时间轴
    //     navigationHelpButton: false, //是否显示右上角的帮助按钮
    //     // // 加载Cesium自带地图
    //     // imageryProvider: new Cesium.TileMapServiceImageryProvider({
    //     //   url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
    //     // }),
    //   });
    //   // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
    //   //   window.Viewer_airroute = viewer;
    //   // 去掉版权信息
    //   that.viewer.cesiumWidget.creditContainer.style.display = 'none';
    //   that.viewer.scene.globe.show = false; //不显示地球，这条和地球透明度选一个就可以

    //   //天地图卫星影像
    //   if (!this.$online) {
    //     // 加载本地地图=======================================
    //     var arcgis_blue = that.viewer.imageryLayers.addImageryProvider(
    //       new Cesium.TileMapServiceImageryProvider({
    //         // 有的版本是用createTileMapServiceImageryProvider
    //         url: Api.TianDiTuMap(),
    //         fileExtension: 'png',
    //       })
    //     );
    //   }
    //   // 开启地形深度检测
    //   that.viewer.scene.globe.depthTestAgainstTerrain = true;

    //   //加载地形
    //   // viewer.terrainProvider = Cesium.createWorldTerrain();

    //   // home定位到具体地点和高度(长沙)
    //   that.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
    //     function (e) {
    //       e.cancel = true;
    //       //你要飞的位置
    //       that.viewer.camera.flyTo({
    //         destination: Cesium.Cartesian3.fromDegrees(
    //           that.$Center[0],
    //           that.$Center[1],
    //           //   119.286808,
    //           //   26.053644,
    //           50000
    //         ),
    //       });
    //     }
    //   );

    //   //阻止摄像头到地下(还有缺陷)
    //   // viewer.clock.onTick.addEventListener(function () {
    //   //   if (viewer.camera.pitch > 0) {
    //   //     viewer.scene.screenSpaceCameraController.enableTilt = false;
    //   //   }
    //   // });
    //   //
    //   // var mousePosition, startMousePosition;
    //   // var handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    //   // handler.setInputAction(function (movement) {
    //   //   mousePosition = startMousePosition = Cesium.Cartesian3.clone(movement.position);
    //   //   handler.setInputAction(function (movement) {
    //   //     mousePosition = movement.endPosition;
    //   //     var y = mousePosition.y - startMousePosition.y;
    //   //     if (y > 0) {
    //   //       viewer.scene.screenSpaceCameraController.enableTilt = true;
    //   //     }
    //   //   }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    //   // }, Cesium.ScreenSpaceEventType.RIGHT_DOWN);
    // },
    // // 显示3D航线或建图
    // shou3DAirRoute(Data, Type) {
    //   let _this = this;
    //   let viewer = this.viewer;
    //   viewer.entities.removeAll();

    //   if (Type == '航线') {
    //     let waypoint = Data.waypoint;
    //     var path = [];
    //     waypoint.forEach((item) => {
    //       path.push([item.lng, item.lat, item.altitude]);
    //     });

    //     // home定位到项目地点和高度
    //     // 高德坐标转WGS84
    //     let Location = gcj02towgs84(path[0][0], path[0][1]);
    //     // console.log("Locatio",Location);
    //     viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
    //       function (e) {
    //         e.cancel = true;
    //         //你要飞的位置
    //         viewer.camera.flyTo({
    //           destination: Cesium.Cartesian3.fromDegrees(
    //             Location[0],
    //             Location[1],
    //             1000
    //           ),
    //         });
    //       }
    //     );

    //     let WGS84_path = [];

    //     // 添加项目图标
    //     path.forEach((item, ind) => {
    //       // 高德坐标转WGS84
    //       let markerLocation = gcj02towgs84(item[0], item[1]);
    //       let lng = markerLocation[0];
    //       let lat = markerLocation[1];
    //       WGS84_path.push(markerLocation);

    //       viewer.entities.add({
    //         // id:Data.id,
    //         name: '航点',
    //         position: Cesium.Cartesian3.fromDegrees(lng, lat, item[2]),
    //         // polyline : {// 画线
    //         //   positions : Cesium.Cartesian3.fromDegreesArrayHeights(
    //         //           [ lng,lat,0, lng,lat,item[2]]
    //         //   ),
    //         //   width : 1,
    //         //   material : new Cesium.PolylineOutlineMaterialProperty({
    //         //     color : Cesium.Color.RED,
    //         //     outlineWidth : 0,
    //         //     // outlineColor : Cesium.Color.BLACK
    //         //   })
    //         // },
    //         // billboard : { //图标
    //         //   image : require('../../assets/img/Marker/uav.png')
    //         // },
    //         model: {
    //           // 模型路径
    //           uri: require('@/assets/glb/aaa.glb'),
    //           // 模型最小刻度
    //           // minimumPixelSize: 128,
    //           // 模型最大刻度
    //           // maximumSize: 128,
    //           //设置模型最大放大大小
    //           // maximumScale : 200,
    //           // 模型是否可见
    //           show: true,
    //           // 模型轮廓颜色
    //           // silhouetteColor:Cesium.Color.WHITE,
    //           // 模型颜色  ，这里可以设置颜色的变化
    //           // color: Cesium.Color.LIME ,
    //           // 仅用于调试，显示魔仙绘制时的线框
    //           debugWireframe: false,
    //           // 仅用于调试。显示模型绘制时的边界球。
    //           debugShowBoundingVolume: false,
    //           scale: 50,
    //           //是否运行模型中的动画效果
    //           runAnimations: false,
    //         },
    //         label: {
    //           //定义标签title
    //           text: '航点 : ' + (ind + 1),
    //           font: 'normal 14px MicroSoft YaHei',
    //           scale: 0.8,
    //           style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    //           color: Cesium.Color.WHITE,
    //           showBackground: true,
    //           horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
    //           verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
    //           pixelOffset: new Cesium.Cartesian2(0, -30),
    //         },
    //         DATA: Data,
    //         ind: waypoint[ind].order,
    //       });
    //     });
    //     waypoint.forEach((item, ind) => {
    //       WGS84_path[ind].push(Number(item.altitude));
    //     });
    //     let positions = [];
    //     WGS84_path.forEach((item) => {
    //       positions.push(item[0], item[1], item[2]);
    //     });
    //     // console.log("画线的路径",positions);
    //     // 画线
    //     viewer.entities.add({
    //       // id:item.pm_id,
    //       name: '航线',
    //       polyline: {
    //         // 画线
    //         positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
    //         width: 2,
    //         material: new Cesium.PolylineOutlineMaterialProperty({
    //           color: new Cesium.Color(47 / 255, 204 / 255, 113 / 255, 1),
    //           outlineWidth: 0,
    //           // outlineColor : Cesium.Color.BLACK
    //         }),
    //       },
    //     });
    //     viewer.zoomTo(viewer.entities);

    //     //自定义单击事件
    //     viewer.screenSpaceEventHandler.setInputAction(function (click) {
    //       // 处理鼠标按下事件，获取鼠标当前位置
    //       // console.log(click);
    //       var feature = viewer.scene.pick(click.position);
    //       //选中某模型
    //       // console.log("feature.DATA",feature);
    //       if (feature && feature.id.DATA) {
    //         _this.shouPicture_3D(feature.id);
    //       }
    //     }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    //   } else if (Type == '建图') {
    //   }
    // },
    // // 点击了3D航点 查看照片
    // shouPicture_3D(data) {
    //   this.$_bus.$emit('Into_Picture', data);
    // },
    // // 切换地图
    // Map3DSwitch() {
    //   this.Shou3D = !this.Shou3D;
    //   if (this.Shou3D) {
    //   }
    // },
    // //隐藏3D地图
    // close3DMap() {
    //   this.Shou3D = false;
    //   this.MapSwitchIconShou = false;
    // },
    //显示正射地图-------------------5/29暂时取消功能,在建图任务查看航线区域时要求展示按时间分类的模型或正射
    // shouOrthophotoMap(e) {
    //   this.$_bus.$emit('Into_OrthophotoMap', e.target);
    // },

    // 初始化虚拟护栏
    initNFzone() {
      let that = this
      //   this.map.clearMap();
      if (this.NFzone.length > 0) {
        that.map.remove(that.NFzone)
        this.NFzone = []
      }

      this.$post(GetNotFlyArea(), {
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = []
            element.local_data.forEach((ele) => {
              path.push(
                // new AMap.LngLat(
                //   window.gcj02towgs84(ele.lng,ele.lat)[0],
                //   window.gcj02towgs84(ele.lng, ele.lat)[1]
                // )
                new AMap.LngLat(ele.lng, ele.lat)
              )
            })

            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            })
            that.NFzone.push(polygon)
          })
          that.map.add(that.NFzone)
        }
      })
    },

    /**
     * 航点图片视频展示
     */
    changeTab(n) {
      this.tab = n
      let lis = document.querySelectorAll('.tabs>li')
      this.imgdata = []
      this.videodata = []
      lis.forEach((e) => {
        e.setAttribute('class', '')
      })
      lis[n].setAttribute('class', 'active')
      if (this.tab !== 0) {
        this.loading = true
        this.$post(Api.airRouteMedia(), {
          client: 'web',
          air_route_id: JSON.parse(sessionStorage.getItem('getTaskData')).id,
        }).then((res) => {
          if (res.code == 1) {
            if (res.data.img_list) {
              this.imgdata = res.data.img_list.reverse()
              console.log('this.imgdata', this.imgdata)
            }
            if (res.data.video_list) {
              this.videodata = res.data.video_list
            }
            // this.filetitle = res.data.AirRouteName;
            // this.dirName = res.data.dirName;
          }
          this.loading = false
        })
      }
    },
    // 图片视频大图查看
    /**
     * 照片
     */
    // 照片备注编辑
    editImgName(id, name) {
      this.editImgNameModel = true
      this.editImgNameId = id
      this.imgRemark = name
    },
    submitImgRemark() {
      this.$post(Api.alterAirRouteImageRemarks(), {
        imagId: this.editImgNameId,
        remark: this.imgRemark,
      }).then((res) => {
        if (res.code == 1) {
          this.loading = true
          this.imgRemark = ''
          this.$post(Api.airRouteMedia(), {
            client: 'web',
            air_route_id: JSON.parse(sessionStorage.getItem('getTaskData')).id,
          }).then((res) => {
            if (res.code == 1) {
              if (res.data.img_list) {
                this.imgdata = res.data.img_list.reverse()
              }
              if (res.data.video_list) {
                this.videodata = res.data.video_list
              }
              //   this.filetitle = res.data.AirRouteName;
              //   this.dirName = res.data.dirName;
            }
            this.loading = false
          })
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    cancelImgRemark() {
      this.imgRemark = ''
    },
    // 查看大图
    bigImg(m, idx) {
      //   let n = [];
      //   m.img_list_point.forEach((e) => {
      //     n.push(e.path);
      //   });
      this.bigIndex = idx
      this.bigImgList = m.img_list_point
      this.bigImgTime = m.date
      this.imgModal = true
    },

    // 关闭弹窗
    closeImgModal() {
      //   document.documentElement.style.overflowY = "auto";
      this.imgModal = false
      this.bigIndex = 0
      this.bigImgList = []
      this.bigImgTime = ''
    },

    /**
     * 查看原图
     */
    showImg(arr, index) {
      let imgPath = [arr[index]]
      this.$viewerApi({
        images: imgPath.map((item) => item.path),
      })
    },

    /**
     * 视频
     */
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden'
      this.bigVideo = path
      this.videoModal = true
    },
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto'
      this.$refs.video.pause()
      this.bigVideo = ''
      this.videoModal = false
    },

    // 蒙版透明度
    changeMask(e) {
      this.mask.setOptions({ fillOpacity: 1 - this.maskOpacity / 100 })
    },
    //格式化时间 年-月-日 时:分:秒
    dateFormat(time) {
      const t = new Date(time)
      // 日期格式
      const format = 'Y-m-d h:i:s'
      const year = t.getFullYear()
      // 由于 getMonth 返回值会比正常月份小 1
      const month = t.getMonth() + 1
      const day = t.getDate()
      const hours = t.getHours()
      const minutes = t.getMinutes()
      const seconds = t.getSeconds()
      const hash = {
        Y: year,
        m: month,
        d: day,
        h: hours,
        i: minutes,
        s: seconds,
      }
      return format.replace(/\w/g, (o) => {
        return hash[o]
      })
    },
    scrawlImg(item) {
      console.log(item)
      this.openDrawing = true
      this.instance = new ImageEditor(
        document.querySelector('#tui-image-editor'),
        {
          includeUI: {
            loadImage: {
              path: item.path,
              name: 'image',
            },
            initMenu: 'draw', // 默认打开的菜单项
            menuBarPosition: 'bottom', // 菜单所在的位置
            locale: locale_zh,
            theme: customTheme, // 自定义样式
          },
          cssMaxWidth: 1800, // canvas 最大宽度
          cssMaxHeight: 800, // canvas 最大高度
        }
      )
      document.getElementsByClassName('tui-image-editor-main')[0].style.top =
        '45px' // 图片距顶部工具栏的距离
    },
  },

  mounted() {
    this.loading = false
    let layer = []
    if (this.$online) {
      layer = new AMap.TileLayer()
      //卫星图
      this.satelliteLayer = new AMap.TileLayer.Satellite()
      // 路网图
      this.roadNetLayer = new AMap.TileLayer.RoadNet()
    } else {
      layer = new AMap.TileLayer({
        getTileUrl: function (x, y, z) {
          return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
        },
        zIndex: 10,
      })
      //卫星图
      this.satelliteLayer = new AMap.TileLayer({
        getTileUrl: function (x, y, z) {
          return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png'
        },
        zIndex: 10,
      })
    }
    //创建地图
    this.map = new AMap.Map('centerMap', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 11, //初始化地图层级
      center: this.$Center, //初始化地图中心点
      layers: [layer],
      //   layers: [
      //   AMap.TileLayer()
      //     new AMap.TileLayer({
      //       tileUrl: function (x, y, z) {
      //         return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
      //       },
      //       zIndex: 0,
      //     }),
      //   ],
    })
    this.map.add(this.satelliteLayer)
    // 修改主题样式
    // if (this.$online) {
    //   this.map.setMapStyle('amap://styles/whitesmoke');
    // }
    // 注册高德测量
    this.mouseTool = new AMap.MouseTool(this.map)

    //默认不显示标注
    var features = ['bg', 'road', 'building']
    this.map.setFeatures(features)

    // //3D地图创建
    // this.Init_map_cesium();

    // // 初始化虚拟护栏
    // this.initNFzone();
    this.mask = new AMap.Polygon({
      path: [
        new AMap.LngLat(-360, 90, true),
        new AMap.LngLat(-360, -90, true),
        new AMap.LngLat(360, -90, true),
        new AMap.LngLat(360, 90, true),
      ],
      strokeColor: '#000',
      strokeWeight: 1,
      fillColor: '#000',
      fillOpacity: 1 - this.maskOpacity / 100,
      bubble: true,
    })
    this.map.add(this.mask)
  },

  beforeDestroy() {
    if (this.viewer != '') {
      this.viewer.destroy()
      this.viewer = ''
    }
  },
}
</script> 

<style scoped lang="scss">
header {
  background-color: $xf_hui1;
  color: $font_color_1;
  position: relative;
  width: 100%;
  z-index: 2;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.18rem;
  padding: 0 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .getBack {
    cursor: pointer;

    i {
      font-size: 0.25rem;
      margin-right: 0.2rem;
      line-height: 0.5rem;
    }

    span {
      font-size: 0.18rem;
      line-height: 0.5rem;
    }
  }
  .tabs {
    display: flex;
    height: 0.3rem;
    justify-content: space-around;
    align-items: center;
    li {
      color: $white;
      //   border: 1px solid $white;
      opacity: 1;
      border-radius: 0.15rem;
      cursor: pointer;
      text-align: center;
      width: 0.68rem;
      height: 0.3rem;
      line-height: 0.3rem;
    }
    .active {
      //   border: 1px solid #409eff;
      background-color: $black;
      //   color: #fff;
    }
  }
}
main {
  display: flex;
  height: 100%;
  overflow: auto;
}
#project {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #333;
  .centerMap {
    width: 100%;
    height: 100%;
  }

  //   .center3DMap {
  //     width: 100%;
  //     height: 900px;
  //   }

  /deep/ .amap-marker-label {
    //修改高德默认label样式
    background-color: #fff;
    text-align: center;
    width: 120px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    overflow: hidden;
    padding: 0 4px;

    .mark_label {
      p {
        line-height: 25px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  /deep/ .amap-info-contentContainer {
    .amap-info-content {
      border-radius: 10px;
      padding: 15px 35px 15px 25px;

      .marker-content {
        li {
          line-height: 20px;

          i {
            margin-right: 6px;
            font-size: 16px;
          }
        }
      }
    }

    .amap-info-close {
      right: 10px !important;
      top: 10px !important;
    }
  }

  .mapControl {
    width: 50px;
    max-height: 400px;
    position: absolute;
    bottom: 50px;
    right: 0px;

    .item {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #111;
      opacity: 0.6;
      cursor: pointer;
      margin-bottom: 16px;
      position: relative;

      img {
        width: 20px;
      }

      .measureBox {
        position: absolute;
        top: 0;
        left: -120px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          padding: 3px 10px;
          background-color: #333;
          opacity: 0.6;
          border-radius: 2px;
          margin-right: 10px;
          span {
            font-size: 16px;
            color: #fff;
            
          }
        }

        :nth-child(1) {
          opacity: 1;
        }
      }

      .hierarchyBox {
        z-index: 400 !important;
        border-radius: 5px;
        position: absolute;
        bottom: 0;
        // left: -90px;
        right: 40px;
        background-color: #333;
        padding: 10px;

        > div {
          width: 110px;
          margin-bottom: 6px;

          input {
            float: left;
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          span {
            float: right;
            font-size: 12px;
            color: #fff;
            line-height: 16px;
          }
        }

        hr {
          margin: 15px 0;
        }
      }
    }

    .item:hover {
      opacity: 1;
    }
  }

  //   .MapSwitch {
  //     width: 32px;
  //     height: 32px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     border-radius: 6px;
  //     background-color: #333;
  //     opacity: 0.5;
  //     cursor: pointer;
  //     position: absolute;
  //     top: 8px;
  //     right: 50px;

  //     img {
  //       width: 80%;
  //       height: auto;
  //     }
  //   }

  #Map3D {
    opacity: 1;
  }

  .searchBox {
    position: absolute;

    top: 0.3rem;
    width: 100%;
    height: 0.5rem;
    #searchMap_2 {
      height: 0.4rem;
      border-radius: 0.5rem;
      border: none;
      padding-left: 0.55rem;
      width: 5.5rem;
      margin: 0 auto;
      display: block;
      box-shadow: 0 0 4px #000;
    }
    // /deep/ .ivu-input {
    //   height: 40px;
    //   border-radius: 50px;
    //   border: none;
    //   padding-left: 55px;
    //   box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
    // }

    // /deep/ .ivu-input:focus {
    //   outline: none;
    //   box-shadow: 0 0 0 2px transparent;
    //   border: 1px solid #409eff;
    // }

    // /deep/ .ivu-input-prefix,
    // .ivu-input-suffix {
    //   width: 60px;
    //   height: 40px;
    //   line-height: 40px;

    //   i {
    //     font-size: 25px;
    //     line-height: 40px;
    //   }
    // }
  }
}
.unfoldBth {
  width: 30px;
  height: 76px;
  background-color: $xf_hui1;
  border-radius: 4px 0 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 340px;
  top: 39px;
  // @include ct;

  img {
    width: 14px;
    height: 24px;
  }

  i {
    font-size: 25px;
    color: $white;
  }
}
.leftBar {
  width: 340px;
  height: 100%;
  background-color: $xf_hei2;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  z-index: 400 !important;
}

.leftBar::-webkit-scrollbar {
  display: none;
}
.noMedia {
  font-size: 16px;
  text-align: center;
  margin: 0 AUTO;
  //   padding-bottom: 2rem;
  img {
    margin: 1rem auto 0.2rem;
  }
}
// 图片
.route-info {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  span {
    font-weight: bolder;
    margin-right: 5px;
  }
}
.drawModal {
  width: 100%;
  height: 100%;
  background: #333;
  .drawing-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.img-list {
  padding: 20px 0 15px 28px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
    height: 21px;
  }
  ul {
    li {
      display: inline-block;
      width: 230px;
      //   height: 230px;
      overflow: hidden;
      margin-right: 25px;
      margin-bottom: 15px;
      cursor: pointer;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
        height: 230px;
      }
      p {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 14px;
        white-space: nowrap;
        color: #ddd;
        span {
          color: #999;
        }
      }
      span {
        position: absolute;
        bottom: -30px;
        right: -30px;
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        background-color: #3bceb3;
        font-size: 16px;
        transition: all 0.3s;
      }
      .scrawl {
        position: absolute;
        bottom: -30px;
        left: -30px;
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        background-color: #3bceb3;
        font-size: 16px;
        transition: all 0.3s;
      }
    }
    li:hover {
      span {
        bottom: 3px;
        right: 3px;
      }
      .scrawl {
        bottom: 3px;
        left: 3px;
      }
    }
  }
}
// 视频
.video-list {
  padding: 20px 0 15px 28px;
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  li {
    display: inline-block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-right: 25px;
    cursor: pointer;
    position: relative;
    img {
      height: 200px;
      margin: 0 auto;
    }
  }
}

.media-date {
  font-size: 16px;
  display: inline-block;
  padding: 0 10px;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: #595a5c;
}

// 弹窗
aside {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  .img-modal-time {
    color: #fff;
    font-size: 16px;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  .big-img-box {
    width: 90%;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
  .arrow {
    position: absolute;
    top: 51%;
    width: 40px;
    height: 40px;
    line-height: 45px;
    text-align: center;
    background-color: #000;
    border-radius: 50%;
    cursor: pointer;
  }
  .big-list-box {
    width: 100%;
    height: 100px;
    background-color: #000;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      li {
        width: 62px;
        height: 62px;
        overflow: hidden;
        margin: 15px 5px 0;
        border: 4px solid #000;
        box-sizing: content-box;
        img {
          height: 62px;
          margin: 0 auto;
        }
      }
      .active {
        border: 4px solid #4c98ff;
      }
    }
  }
}
article {
  position: fixed;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 23, 23, 0.84);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 99;
    background-color: #000;
    border-radius: 10px;
  }
  video {
    max-height: 100%;
    max-width: 100%;
  }
}
/deep/ .amap-marker-label {
  overflow: unset !important;
  width: 80px !important;
}
</style>