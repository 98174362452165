<template>
  <!-- 废弃 -->
  <div style="padding:20px">
    <header>
      <h1>全景展示</h1>
      <Button type="info"
              @click="toMgr">全景图管理</Button>
    </header>

    <!-- 列表 -->
    <div class="container">
      <div v-for="item in tabledata"
           @click="toDetail(item)"
           class="item"
           :key="item.id"><img :src="item.panoramaImgPath"
             :alt="item.name">
        <p class="txt">{{item.name}}</p>
      </div>
      <div v-if="!tabledata.length">暂无数据</div>
    </div>

    <div class="page"
         v-if="total>10">
      <Page show-total
            :page-size="limit"
            :total="total"
            :current="parseInt(page)"
            size="small"
            show-sizer
            :page-size-opts='[10,15,20]'
            @on-page-size-change="pageSizeChange"
            @on-change="pageChange" />
    </div>
  </div>
</template>

<script>
import { PanoramawebList } from '@/utils/javaApi.js';
export default {
  name: 'vrList',

  data() {
    return {
      tabledata: [],
      limit: 10,
      page: 1,
      pid: 0,
      total: 8,
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push({
        name: 'DetailPanorama',
        query: { id: item.id },
      });
    },
    toMgr() {
      console.log('点了');
      this.$router.push({
        name: 'vrMgr',
      });
    },
    // 获取全景图列表
    getPanorame() {
      this.$post(PanoramawebList(), {
        page: this.page,
        limit: this.limit,
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.total = res.data.total;
            this.tabledata = res.data.data;
            // 初始化获取
          } else {
            this.$Message.error(res.msg_customer);
          }
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
    // 翻页
    pageChange(ind) {
      this.page = ind;
      this.getPanorame();
    },
    pageSizeChange(l) {
      this.limit = l;
      this.pageChange(1);
    },
  },

  mounted() {
    this.getPanorame();
  },
};
</script> 

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  .item {
    padding: 10px;
    width: 16.66%;
    height: 200px;
    cursor: pointer;
    .txt {
      padding: 10px 0;
    }
    img {
      width: 100%;
      height: 90%;
    }
  }
}
.page {
  padding: 10px 20px;
  float: right;
}
.thumbnail {
  width: 140px;
  height: 80px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>